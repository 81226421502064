<template>
  <!-- eslint-disable -->
  <b-card>
    <div>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="onSubmit(companyData)">
          <b-row>
            <!-- Field: Full Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Razon Social" label-for="bussiness-name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="companyData.business_name"
                >
                  <b-form-input
                    id="bussiness-name"
                    placeholder="Razon Social"
                    v-model="companyData.business_name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo razon social es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="RUC" label-for="doi">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="companyData.doi"
                >
                  <b-form-input
                    placeholder="RUC"
                    id="doi"
                    v-model="companyData.doi"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo doi es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Email -->
            <b-col cols="12" md="4">
              <b-form-group label="Dirección" label-for="address">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="companyData.address"
                >
                  <b-form-input
                    id="address"
                    v-model="companyData.address"
                    type="text"
                    placeholder="Dirección"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo dirección es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="isDisabled"
          >
            <b-spinner v-if="isDisabled" small />
            <span v-if="isDisabled" class="px-1">guardando...</span>
            <span v-else>Guardar cambios</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            type="reset"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Resetear
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import CompanyService from "@/services/CompanyService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      companyData: {
        business_name: "",
        doi: "",
        address: "",
      },
      required,
      isDisabled: false,
    };
  },
  mounted() {},
  methods: {
    async onSubmit(data) {
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) {
        console.log("NO VALIDADO");
      } else {
        this.isDisabled = true
        const resp = await CompanyService.saveCompany(data, this.$store);
        if (resp.success) {
          this.$swal({
            title: "Añadido",
            text: "La empresa ha sido registrada",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push("/company/list");
        } else {
          this.$swal({
            title: "Error!",
            text: " Hubo un error al guardar la empresa",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    async addCompany(data) {},
  },
};
</script>

<style></style>
